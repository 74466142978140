import React from 'react';
import './App.css';
import {Navigation} from "./Navigation/Navigation";
import {Body} from "./Body/Body";
import {Footer} from "./Footer/Footer";

function App() {
  return (
      <React.Fragment>
          <Navigation />
          <Body />
          <Footer />
      </React.Fragment>
  );
}

export default App;