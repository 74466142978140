// @flow
import * as React from 'react';
import classes from './DarkMode.module.css';
import Form from "react-bootstrap/Form";

type Props = {
    change: Function,
    darkMode: boolean
};

export function DarkMode(props: Props) {

    const dark = props.darkMode ? classes.dark : null

    return (
        <div className={classes.darkModeContrainer}>
            <Form className={classes.darkModeForm}>
                <Form.Check
                    className={dark}
                    defaultChecked={props.darkMode}
                    onChange={props.change}
                    type="switch"
                    id="custom-switch"
                    label="Dark Mode"
                />
            </Form>
        </div>
    );
};