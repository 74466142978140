// @flow
import * as React from 'react';
import classes from './Footer.module.css'

type Props = {

};

export function Footer(props: Props) {
    return (
        <div className={classes.footer}>
            <img className={classes.footerImage} alt={"EC logo"} src={'./ec_logo_hc_0.png'} />
            <p className={classes.footerText}>This project has been funded with the support from the European Commission. The website reflects the views only of the author, and the Commission cannot be held responsible for any use which may be made of the information contained therein.</p>
            <p className={classes.terms}><a target={"blank"} href={"https://uni-foundation.eu/privacy-terms/"}>Privacy & Terms</a></p>
        </div>
    );
};