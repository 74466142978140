// @flow
import * as React from 'react';
import Navbar from "react-bootstrap/Navbar";
import classes from './Navigation.module.css'
import Container from "react-bootstrap/Container";

type Props = {

};

export function Navigation(props: Props) {
    return (
        <Navbar className={classes.navbar} bg="primary" variant="dark">
                <Container className={"justify-content-center"}>
                    <h1 className={classes.header}>
                        Erasmus+ Covid-19 <br/> Mobility Status
                    </h1>
                </Container>
        </Navbar>
    );
};