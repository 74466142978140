// @flow
import React, { Suspense } from 'react';
import {Spinner} from "react-bootstrap";
import axios from 'axios';
import {DarkMode} from "./DarkMode/DarkMode";
import classes from './StatusPage.module.css';
import SearchBar from "./SearchBar/SearchBar";

type Props = null;

type State = {
    search: string,
    institutions: [],
    searchResults: [],
    loaded: boolean,
    darkMode: boolean
};

export class StatusPage extends React.Component<Props, State> {

    constructor() {
        super();

        this.state = {
            search: "",
            institutions: [],
            searchResults: [],
            loaded: false,
            darkMode: JSON.parse(localStorage.getItem('darkMode'))
        }
        this.inputRef = React.createRef();
    }

    searchHandler = (event) => {

        if (this.state.loaded) {
            this.setState({search: event.target.value})

            if (event.target.value === '') {
                this.setState({searchResults: this.state.institutions});
            }

            let institutions = [...this.state.institutions]

            const val = event.target.value;

            const searchResults = institutions.filter(institution => {
                const name = (institution.name.toLowerCase().search(val.toLowerCase()) !== -1);
                const pic = (institution.pic.toLowerCase().search(val.toLowerCase()) !== -1);
                const erasmusCode = (institution.erasmusCode.toLowerCase().search(val.toLowerCase()) !== -1);
                const city = (institution.city.toLowerCase().search(val.toLowerCase()) !== -1);
                const country = (institution.country.toLowerCase().search(val.toLowerCase()) !== -1);
                return name || pic || city || erasmusCode || country;
            })
            this.setState({searchResults: searchResults});
        }
    }

    switchHandler = (event) => {
        localStorage.setItem('darkMode', JSON.stringify(event.target.checked))
        this.setState({darkMode: event.target.checked})
    }

    componentDidMount() {

        axios.get(process.env.REACT_APP_DASHBOARD_API_HOST + '/api/covidstatus')
            .then( response => {
                if (this.inputRef.current !== null) {
                    if (this.inputRef.current.value === '') {
                        const val = this.inputRef.current.value
                        const data = [...response.data]
                        const displayData = data.filter(institution => institution.name.toLowerCase().search(val.toLowerCase()) !== -1)
                        this.setState({loaded: true, searchResults: displayData, institutions: data})
                    }
                    else {
                        this.setState({loaded: true, searchResults: [...response.data], institutions: [...response.data]})
                    }
                    this.inputRef.current.focus()
                }
                else {
                    this.setState({loaded: true, institutions: [...response.data], searchResults: [...response.data]})
                }
            } )

    }

    render() {
        const InstitutionsGrid = React.lazy(() => import("./InstitutionsGrid/InstitutionsGrid"))
        const Buttons = React.lazy(() => import("./Buttons/Buttons"))

        const dark = this.state.darkMode ? classes.dark : null

        const placeholder = (
            <div className={[classes.placeholder, dark].join(' ')} />
        )

        let page;
        if (this.state.loaded) {
            page = (
                <div className={dark}>
                    <DarkMode darkMode={this.state.darkMode} change={this.switchHandler} />
                    <SearchBar darkMode={this.state.darkMode} inputRef={this.inputRef} searchHandler={this.searchHandler} />
                    <Suspense fallback={placeholder}>
                        <Buttons darkMode={this.state.darkMode} />
                        <InstitutionsGrid darkMode={this.state.darkMode} results={this.state.searchResults} />
                    </Suspense>
                </div>
            );
        } else {
            page = (
                <div className={dark}>
                    <DarkMode darkMode={this.state.darkMode} change={this.switchHandler} />
                    <div className={[classes.spinnerContainer].join(' ')}>
                        <Spinner className={[classes.spinner, dark].join(' ')} animation={"border"} />
                    </div>
                </div>
            )
        }

        return page;
    };
};