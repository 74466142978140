// @flow
import * as React from 'react';
import {StatusPage} from "./StatusPage/StatusPage";


type Props = {

};
export const Body = (props: Props) => {
    return (
        <React.Fragment>
            <StatusPage/>
        </React.Fragment>
    );
};