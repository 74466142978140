// @flow
import * as React from 'react';
import Container from "react-bootstrap/Container";
import classes from './SearchBar.module.css';

type Props = {
    inputRef: React.Ref,
    searchHandler: Function,
    darkMode: boolean
};

export default function SearchBar(props: Props) {

    const dark = props.darkMode ? classes.dark : null
    const searchBarDark = props.darkMode ? classes.searchBarDark : classes.searchBar;

    return (
        <Container className={[classes.searchContainer, dark]}>
            <p className={[classes.searchLabel, 'lead', dark].join(' ')}>Search for a Higher Education Institution to see their latest available information about the Erasmus+ and Swiss-European Mobility Programmes during the Covid-19 pandemic</p>
            <input
                ref={props.inputRef}
                onChange={props.searchHandler}
                className={searchBarDark}
                type={"text"} placeholder={"Ex: Institution; City; Country; Erasmus code; PIC\n"}
            />

        </Container>
    );
};